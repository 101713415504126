<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      'Downloads': 'Downloads',
    },
    es: {
      'Downloads': 'Descargas',
    }
  },
  components: { Layout },
  data() {
    return {
      folders: []
    };
  },
  methods: {
    getList: function () {
      api
        .get('downloads')
        .then((response) => {
          if (response.data.status == 'success') {
            this.folders = response.data.folders
          }
        })
    }
  },
  mounted() {
    this.getList()
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Downloads') }}</h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card" v-for="(folder,index) in folders" :key="index">
          <div class="card-header bg-soft-dark rounded-top">
            <h5 class="m-0">{{ folder.name }}</h5>
          </div>
          <div class="card-body p-0">
            <div class="file" v-for="(download,key) in folder.list" :key="key">
              <div class="text-truncate">
                <a class="d-block p-3 text-dark" target="_blank" :href="download.file.url">
                  <i class="bx bx-file-blank align-top font-size-17 mr-2"></i>
                  {{ download.title }} - <small>{{ (download.file.size / 1024).toFixed(2) }} MB</small>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.file:nth-of-type(odd) {
  background: #F8F8FB;
}
@media (max-width: 500px) {
  .text-truncate {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 10px;
    text-overflow: ellipsis;
    width: calc(100v);
  }
}
</style>